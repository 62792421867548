import { env } from '@/env';
import { useUserStore } from '@/stores/user';
import axios from 'axios';

// 從 localStorage 獲取 JWT token
const getToken = () => {
  return localStorage.getItem('jwtToken');
};

// 創建 axios 實例
const axiosInstance = axios.create({
  baseURL: env.apiBaseUrl,
  timeout: 10000 // 請求超時時間
});

// 設置請求攔截器來附加 JWT token
axiosInstance.interceptors.request.use(
  (config) => {
    // todo: 填入 token
    const userStore = useUserStore();
    const router = useRouter();
    const { token } = userStore;
    if (useExpireToken(userStore.token)) {
      alert('登入過期，請重新登入');
      router.replace('/');
      // todo: 直接登出
    }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 設置響應攔截器來處理 token 過期等情況
axiosInstance.interceptors.response.use(
  (response) => {
    // 任何狀態碼在 2xx 範圍內的都會觸發這個函數
    return response;
  },
  (error) => {
    // 任何超出 2xx 範圍的狀態碼都會觸發這個函數
    if (error.response && error.response.status === 401) {
      // 這裡可以處理 token 過期的情況，例如跳轉到登錄頁面
      // todo: 傳送log到後端。
    }
    // todo: api錯誤提示訊息。
    return Promise.reject(error);
  }
);

export default axiosInstance;
