<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: ''
    }
});

const iconName = computed(() => `#icon-${props.name}`);
const svgClass = computed(() => {
    if (props.name) {
        return `svg-icon icon-${props.name}`;
    }
    return 'svg-icon';
});
</script>

<template>
    <svg :class="svgClass" v-bind="$attrs" :style="{ color: color }">
        <use :href="iconName" />
    </svg>
</template>

<style lang="scss" scoped>
$icon-width-default: 24px;
$icon-height-default: 24px;

$icon-width-sm: 16px;
$icon-height-sm: 16px;

$icon-width-lg: 40px;
$icon-height-lg: 40px;

.svg-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $icon-width-default;
    height: $icon-height-default;

    &.sm {
        width: $icon-width-sm;
        height: $icon-height-sm;
    }

    &.lg {
        width: $icon-width-lg;
        height: $icon-height-lg;
    }

    &.logo {
        width: 100%;
        height: 40px;
    }
}
</style>
