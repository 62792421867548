import type { ClassDetail } from '@/models/interface/course';

export class UtilCommon {
  static generateUUID(length: number = 20) {
    const uid = Array.from({ length }, () => 'x').join('').replace(/[x]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    // console.log('====generateUUID', length, uid)
    return uid;
  }

  /**
   * 取得時間範圍內每一天的日期
   */
  static getWeekDates = (weekRange: { startDate: Date; endDate: Date }): Date[] => {
    const { startDate, endDate } = weekRange;
    const weekDates: Date[] = [];

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      weekDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // 將日期加一天
    }

    return weekDates;
  };

  /**
   * 取得本週起訖時間給課表用
   * @param date 給哪一天，就會抓那週的起訖時間。
   */
  static getTimeRangeForWeek(date: Date) {
    // 將日期轉換為當天的0點時間
    const startOfDay = new Date(date.setHours(0, 0, 0, 0));

    // 計算本週的第一天(星期一)和最後一天(星期日)
    const dayOfWeek = startOfDay.getDay();
    const startOfWeek = new Date(startOfDay);
    startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // 如果是星期日，則需減去6天來得到週一

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // 星期日

    // 返回本週的起始和結束時間（以timestamp表示）
    return {
      startTimestamp: startOfWeek.getTime() / 1000,
      endTimestamp: endOfWeek.getTime() / 1000
    };
  }
  /**
   * 給後端的timestamp，需要以「秒」為單位，故除1000。
   */
  static toTimestamp(dateString: string): number {
    const seconds = Math.floor(new Date(dateString).getTime() / 1000);
    return seconds;
  }
  /**
   * 傳入日期，取得該日 00:00:00 的timestamp
   */
  static getTimestamp(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).getTime();
  }

  /**
   * number to byte string, like 5 * 1024 * 1024 = 5MB
   */
  static formatBytes(bytes: number, decimals: number = 0): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes[i]}`;
  }

  /**
   * 計算字數
   */
  static processCharacters(str: string): {
    chinese: number;
    english: number;
    digitsAndSymbols: number;
    other: number;
    str: string;
  } {
    const chineseRegex = /[\u4E00-\u9FA5]/g; // 匹配中文字符的正規表達式
    const englishRegex = /[a-zA-Z]/g; // 匹配英文字符的正規表達式
    const digitSymbolRegex = /[\d\s~`!@#$%^&*()-=_+[\]{}|;:'",.<>/?\\。、，]/g; // 匹配數字、特殊符號、以及中文的正規表達式
    let newStr = '';

    let chineseCount = 0;
    let englishCount = 0;
    let digitSymbolCount = 0;
    let otherCount = 0;

    // 遍历字符串并进行匹配
    for (let char of str) {
      if (char.match(chineseRegex)) {
        chineseCount++;
        newStr += char;
      } else if (char.match(englishRegex)) {
        englishCount++;
        newStr += char;
      } else if (char.match(digitSymbolRegex)) {
        digitSymbolCount++;
        newStr += char;
      } else {
        otherCount++;
      }
    }

    return {
      chinese: chineseCount,
      english: englishCount,
      digitsAndSymbols: digitSymbolCount,
      other: otherCount,
      str: newStr
    };
  }

  /**
   * 取得當前月份縮寫
   */
  static getMonthAbbreviation(date: Date): string {
    // 若有需要i18n，可以改用注入的方式，就是這個陣列是從API來，或者i18n JSON來，等等。
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const monthIndex = date.getMonth();
    return monthAbbreviations[monthIndex];
  }

  /**
   * javscript Date to Funday date time format
   */
  static dateToFundayDateString(
    date: Date,
    timezone?: number
  ): {
    date: string; // 2024-06-28
    time: string; // 01:59
  } {
    // default is Taiwan time zone +8 hr
    const _timezone = timezone ?? +8;
    date.setDate(_timezone);
    // ISO: '2024-06-28T01:59:27.968Z'
    const dateTime = date.toISOString().split('T');
    const dateString = dateTime[0];
    const timeString = `${date.getHours()}:00`;
    return {
      date: dateString,
      time: timeString
    };
  }

  /**
   * 課程等級右邊的星星
   */
  static displayStars(level: number): string {
    const fullStar = '★';
    const emptyStar = '☆';
    let stars = '';

    for (let i = 1; i <= 3; i++) {
      stars += i <= level ? fullStar : emptyStar;
    }

    return stars;
  }

  static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static getParamsFromUrl(): Record<string, string | string[]> {
    // 1. 獲取 URL 的查詢字符串
    const queryString = window.location.search;

    // 2. 使用 URLSearchParams 解析查詢字符串
    const urlParams = new URLSearchParams(queryString);

    // 3. 將 URLSearchParams 轉換成普通的 JavaScript 物件
    const paramsObject: Record<string, string | string[]> = Object.fromEntries(urlParams.entries());

    // 如果有重複的參數名，例如 `colors`，需要進一步處理它們變成數組
    for (let [key, value] of urlParams.entries()) {
      if (urlParams.getAll(key).length > 1) {
        paramsObject[key] = urlParams.getAll(key);
      }
    }

    return paramsObject;
  }

  static formatDateToDateTimeString(date: Date): string {
    // 獲取日期的各個部分
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 從0開始計數
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // 組合成所需格式
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  static formatDateToDateStringL(date: Date): string {
    // 獲取日期的各個部分
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 從0開始計數
    const year = date.getFullYear();

    // 組合成所需格式
    return `${year}/${month}/${day}`;
  }

  static formatDateToDateStringM(date: Date): string {
    // 獲取日期的各個部分
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 從0開始計數
    const year = date.getFullYear();

    // 組合成所需格式
    return `${month}/${day}`;
  }

  static formatDateToDateStringS(date: Date): string {
    // 獲取日期的各個部分
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 從0開始計數
    const year = date.getFullYear();

    // 組合成所需格式
    return `${day}`;
  }

  static getLocalStorage<T>(key: string): T | null {
    const val = localStorage.getItem(key);
    if (val) {
      return JSON.parse(val) as T;
    }
    return null;
  }

  static setLocalStorage<T>(key: string, val: T): void {
    localStorage.setItem(key, JSON.stringify(val));
  }

  static formatISODateToYYYYMMDD(isoDate: Date): { yyyy: string; mm: string; dd: string } {
    if (!(isoDate instanceof Date)) {
      throw new Error('isoDate must be a Date object');
    }
    const isoString = isoDate.toISOString();
    let datePart = isoString.split('T')[0];
    let [yyyy, mm, dd] = datePart.split('-');
    return { yyyy, mm, dd };
  }

  // 給技術債課表、會話、寫作、consulting用
  static groupClassesByTime(
    classDetails: ClassDetail[]
  ): Array<{ time: string; data: Array<ClassDetail> }> {
    // 使用 reduce 進行分組
    const grouped = classDetails.reduce(
      (acc, classDetail) => {
        const time = classDetail.classStartTime;
        const group = acc.find((g) => g.time === time);

        if (group) {
          group.data.push(classDetail);
        } else {
          acc.push({ time, data: [classDetail] });
        }

        return acc;
      },
      [] as Array<{ time: string; data: Array<ClassDetail> }>
    );

    // 對每個時間分組的 data 根據 reservedNum 進行排序，從大到小
    grouped.forEach((group) => {
      group.data.sort((a, b) => (a?.reservedNum ?? 0) - (b?.reservedNum ?? 0));
    });

    return grouped;
  }

  /**
   * 課程等級物件：等級名稱、等級顏色
   */
  static getlevelsMap(): Record<
    number,
    {
      name: string;
      color: string;
    }
  > {
    return {
      0: {
        name: 'pre-A1',
        color: '#ff668c'
      },
      1: {
        name: 'A1',
        color: '#20a0a0'
      },
      2: {
        name: 'A2',
        color: '#764fcb'
      },
      3: {
        name: 'B1',
        color: '#0a84ff'
      },
      4: {
        name: 'B2',
        color: '#f28521'
      },
      5: {
        name: 'C1',
        color: 'rgba(25, 25, 25, 0.80)'
      }
    };
  }

  /**
   * 設定Cookie
   * @param name Cookie 的名稱。
   * @param value  Cookie 的值。
   * @param days Cookie 的有效天數。如果不設置天數，則該 Cookie 為會話級別（當前會話結束後自動刪除）。
   * @param path Cookie 的路徑，默認為 /，即整個網站都能讀取該 Cookie。
   */
  static setCookie = (name: string, value: string, days?: number, path = '/') => {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${encodeURIComponent(value || '')}${expires}; path=${path}`;
  };

  /**
   * 抓取Cookie
   * @param name 要獲取的 Cookie 名稱。
   * @returns 對應的 Cookie 值，如果找不到則返回 null。
   */
  static getCookie = (name: string) => {
    const nameEQ = `${name}=`;
    const cookiesArray = document.cookie.split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();

      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length));
      }
    }

    return null;
  };
}
