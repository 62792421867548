<script setup lang='ts'>
const props = defineProps<{
    coverArea?: 'fixed'
}>()
const coverArea = ref(props.coverArea ? props.coverArea : 'absolute')
const showPopup = defineModel("showPopup");
watch(() => showPopup.value, () => {
    if (showPopup.value) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});
</script>
<template>
    <div class="popup-card" v-if="showPopup" @click="showPopup = false">
        <slot></slot>
    </div>
</template>
<style scoped lang='scss'>
.popup-card {
    position: v-bind(coverArea);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000066;
    z-index: 1999;
}
</style>