type CompareType = 'before' | 'after' | 'equal';
const compareDates = (date1: string, date2: string, type: CompareType) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    if (type === 'before') {
        return d1.getTime() < d2.getTime();
    } else if (type === 'after') {
        return d1.getTime() > d2.getTime();
    } else if (type === 'equal') {
        return d1.getTime() === d2.getTime();
    }
};
export default compareDates;
