export const useCurrentDay = () => {
    const current: Ref<Date> = ref(new Date()); // 當前時間

    const currentYear: Ref<string> = computed(() => current.value.getFullYear().toString()); // 當前年份
    const currentMonth: Ref<string> = computed(() => {
        const month = current.value.getMonth() + 1;
        return month < 10 ? month.toString().padStart(2, '0') : month.toString();
    }); // 當前月份
    const currentDate = computed(() => {
        const date = current.value.getDate();
        return date < 10 ? date.toString().padStart(2, '0') : date.toString();
    }); // 當前日期

    return {
        currentYear, // 當前年份
        currentMonth, // 當前月份
        currentDate // 當前日期
    };
};
