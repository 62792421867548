const gtmId = import.meta.env.VITE_GTM_ID;
if (!gtmId) {
  throw new Error('Missing GTM ID');
}

const apiBaseUrl = import.meta.env.VITE_FUNDAY_BASE_URL;
if (!apiBaseUrl) {
  throw new Error('Missing VITE_FUNDAY_BASE_URL');
}

const campusUrl = import.meta.env.VITE_CAMPUS_URL
const fqUrl = import.meta.env.VITE_FQ_URL

export const env = {
    gtmId,
    apiBaseUrl,
    campusUrl,
    fqUrl,
};
