import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { useCourseStore } from '@/stores/course';

/** 免費課程(名師講堂) */
export default async function freeClass({
    to,
    from,
    next,
    router
}: {
    to: RouteLocationNormalized;
    from: RouteLocationNormalized;
    next: NavigationGuardNext;
    router: Router;
}) {
    console.log('[Middleware -- freeClass]');

    const courseStore = useCourseStore();

    courseStore.fnGetFreeCourse();

    return next();
}
