import {jwtDecode} from 'jwt-decode';
const expireToken = (token: string) => {
    if(token===undefined || token === null) return false
    const decodedToken = jwtDecode(token);
    if (decodedToken !== undefined && decodedToken.exp) {
        if (decodedToken.exp < Date.now() / 1000) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
export default expireToken;
