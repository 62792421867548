export const usePopStore = defineStore('pop', () => {
    const loadingStatus = ref(false); // 讀取頁面狀態

    const popList = ref<{ [key: string]: boolean }>({
        profile: false,
        changePwd: false,
        thankYou: false,
        questionnaire: false,
        warning: false,
        newOldSwitcher: false
    });

    const popClose = () => {
        Object.assign(popList.value, {
            profile: false,
            changePwd: false,
            thankYou: false,
            questionnaire: false,
            warning: false
        });
    };

    /** 切換Popup */
    const handleSwitchPop = (to: string, from: string) => {
        popList.value[to] = true;
        popList.value[from] = false;
    };

    const switchLoadingStatus = () => {
        loadingStatus.value = !loadingStatus.value;
    };

    return {
        loadingStatus,
        popList,
        popClose,
        handleSwitchPop,
        switchLoadingStatus
    };
});
