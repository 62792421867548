<script lang="ts" setup>
import SvgIcon from '@/components/base/SvgIcon.vue';
import { useAppStore } from '@/stores/app';

const appStore = useAppStore();
const { state: bulletinState, fetchData } = useBulletin();
const uiState = ref({
    selectedIndex: 0,
})

function handleDecodeURI(data: string) {
    return data
    // return decodeURIComponent(data);
}

const onTogglerBulletin = () => {
    appStore.isBulletOpen = !appStore.isBulletOpen;
}

const onSelect = (index: number) => {
    uiState.value.selectedIndex = index;
}

onMounted(() => {
    // fnGetBullet()
    fetchData()
})
</script>
<template>
    <div class="cover">
        <div class="bullet-container" @click.stop>
            <div class="content-container">
                <div class="content-title">
                    Bulletin
                    <span class="date"> {{ bulletinState.data[uiState.selectedIndex]?.date }}</span>
                </div>
                <div class="content">
                    <li v-for="(value, index) in bulletinState.data[uiState.selectedIndex]?.data">
                        <span v-html="value"/>
                    </li>
                </div>
            </div>
            <div class="list">
                <div class="close" @click="onTogglerBulletin">
                    <SvgIcon name="crossbold" />
                </div>
                <div class="list-item">
                    <div class="item" v-for="(item, index) in bulletinState.data" @click="() => onSelect(index)">
                        <div class="item-date" :class="{ 'active': index === uiState.selectedIndex }">{{ item.date }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bullet-container-mb">
            <div class="pop-top">
                <h2>Bulletin</h2>
                <SvgIcon name="crossbold" @click="onTogglerBulletin" />
            </div>
            <div class="content-list">
                <div v-for="item in bulletinState.data">
                    <p>{{ item.date }}</p>
                    <li v-for="(value, index) in item?.data">
                        <span v-html="value"/>
                    </li>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang='scss' scoped>
.cover {
    width: 100%;
    height: 100%;
}

.bullet-container {
    display: flex;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 850px;
    height: 400px;
    overflow: hidden;
    border-radius: 8px;
    background-color: #fff;

    @media screen and (max-width:1023px) {
        display: none;
    }

    .content-container {
        flex: 1;
        padding: 20px 20px 40px 20px;

        .content-title {
            font-size: 34px;
            font-weight: 700;
            margin-bottom: 8px;
            color: #191919;
            font-family: "Noto Sans TC";

            .date {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .content {
            width: 610px;
            height: 300px;
            padding: 10px 20px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, .05);
            word-break: break-all;
            color: #232323;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            overflow-y: scroll;

            .inner-content {
                height: 95%;
                padding-right: 10px;
                overflow: auto;
                scrollbar-width: thin;
                scrollbar-color: #D7D7D7 rgba(0, 0, 0, 0);

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-track {
                    background: rgba(0, 0, 0, 0);
                }

                &::-webkit-scrollbar-thumb {
                    background: #D7D7D7;
                }
            }

        }
    }

    .list {
        width: 200px;
        height: 100%;
        background-color: #f4f4f4;
        padding: 10px;

        .close {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            .svg-icon {
                width: 14px;
                height: 14px;
            }
        }

        .list-item {
            user-select: none;
            margin: 22px 10px 10px 10px;
            height: 80%;

            overflow: auto;
            scrollbar-width: thin;
            scrollbar-color: #D7D7D7 rgba(0, 0, 0, 0);

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: rgb(215, 215, 215);
            }

            &::-webkit-scrollbar-thumb {
                background: #D7D7D7;
            }

            .item {
                line-height: 1.35;
                margin-bottom: 18px;
                cursor: pointer;
            }

            .item-date {
                color: rgba(25, 25, 25, 0.8);
                font-size: 14px;
                font-weight: 700;

                &.active {
                    color: #F74768;
                }
            }

            .item-content {
                width: 95%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: rgba(25, 25, 25, 0.8);
                font-size: 12px;

                &.active {
                    text-decoration: underline;
                    color: #F74768;
                    font-weight: 700;
                }
            }
        }
    }
}

.bullet-container-mb {
    display: none;
    position: absolute;
    width: 100%;
    background-color: #fff;
    height: 100%;
    left: 0;
    bottom: 0;

    @media screen and (max-width:1023px) {
        display: block;
    }

    .pop-top {
        position: relative;
        padding: 0px 16px;
        text-align: center;

        h2 {
            font-size: 16px;
            font-weight: 700;
            font-family: "Noto Sans TC";
            border-bottom: 1px solid rgba(#333, 0.1);
            padding: 24px 0;
        }

        .svg-icon {
            position: absolute;
            right: 24px;
            top: 24px;
            width: 16px;
            height: 16px;
            color: #333333;
            cursor: pointer;
        }
    }

    .content-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: #efefef;
        height: calc(100% - 70px);
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #D7D7D7 rgba(0, 0, 0, 0);

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: rgb(215, 215, 215);
        }

        &::-webkit-scrollbar-thumb {
            background: #D7D7D7;
        }

        >div {
            padding: 16px 32px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            gap: 8px;

            div {
                font-size: 14px;
            }
        }
    }
}
</style>