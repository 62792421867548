<script setup lang="ts">
import type { CSSProperties } from 'vue';
const props = defineProps<{
  position?: string;
  bgColor?: string;
  zIndex?: number;
}>();

const dotNum = 3;

const style = computed(() => {
  return {
    position: props.position || 'fixed',
    backgroundColor: props.bgColor || 'rgba(244, 244, 244, 0.5)',
    zIndex: props.zIndex || 10000
  } as CSSProperties;
});
</script>
<template>
  <div class="loading" :style="style">
    <div id="loading-dots">
      <div v-for="i in dotNum" class="dot"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.loading {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flex-center();
}

.dot {
  @include circle(15);
  background-color: $color-primary-100;
  display: inline-block;
  margin: 0 8px;
  animation: moveAndChangeColor 1.5s infinite linear alternate;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: 0.25s * $i;
    }
  }
}

// 上下跳動＋變換顏色動畫
@keyframes moveAndChangeColor {
  0%,
  100% {
    transform: translateY(0);
    background-color: $color-primary-100;
  }
  50% {
    transform: translateY(-25px);
    background-color: $color-primary-20;
  }
}
</style>
